<template>
    <div class="fundedAccount-page">
        <section class="blockElement space bg-white fundedBanner pb-0">
            <div class="container">
                <div class="row g-0 align-items-end">
                    <div class="col-12 col-lg-7">
                        <h5 class="secondary mb-3">{{$t('FundedAccount.fundedText1')}}</h5>
                        <h1 class="extrabold h1 mb-md-3 titleArrow">{{$t('FundedAccount.fundedText2')}}</h1>
                        <p v-html="$t('FundedAccount.fundedText3')"></p>
                        <div class="dualButton d-flex align-items-center">
                            <a class="button fillBtn zulu_btn me-md-4 shadow" href="javascript:void(0)">{{$t('FundedAccount.fundedText4')}}</a>
                            <a class="button secondary zulu_btn rounded border-button px-ms-4 shadow" href="javascript:void(0)">{{$t('FundedAccount.fundedText5')}}</a>
                        </div>
                        <v-lazy-image width="405" height="300" class="d-block" src="/assets/images/funded/money-bags-coins.png" :alt="$t('FundedAccount.fundedText6')" />
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="meetImg">
                            <v-lazy-image width="675" height="600" class="d-block" src="/assets/images/funded/meetFund.png" :alt="$t('FundedAccount.fundedText7')" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white Vue3Marquee" v-if="Object.keys(store.allPrices).length">
            <Vue3Marquee class="animation d-flex" :duration="3000" :pauseOnClick="true">
                <div class="marquee-row" v-for="(value, key, index) in Object.values(store.allPrices)" :key="index">
                    <div class="marquee-column">
                        <div class="currencyFlag">
                            <a href="javascript:void(0);">
                                <img :src="static_vars.marketImageSURL + value.currency.toUpperCase().replace(/\//g, '') + '.svg'" :alt="value.currency.toString().split('/')[0].toLowerCase()" :title="value.currency.toString().split('/')[0].toLowerCase()"/>
                                <!-- <p class="mb-0 py-0 px-1 d-inline-flex align-items-center f-14"
                                    :class=" parseFloat(value.lastChangePercentage) >= 0.0 ? 'green' : 'red'">
                                    <i :class="parseFloat(value.dailyPriceChangePercentage) > 0.0 ? 'fa fa-caret-up me-1' : 'fa fa-caret-down me-1'"
                                        aria-hidden="true"></i>
                                    {{ parseFloat(value.lastChangePercentage) >= 0.0 ? "+" : ""}}{{parseFloat(value.lastChangePercentage).toFixed(2)|| 0}}%
                                </p> -->
                            </a>
                        </div>
                        <div class="d-flex align-items-center inboxMob">
                            <span class="f-13">
                            {{value.currency}}
                            </span>
                            <span class="f-12">
                                {{
                                    value.buyPrice
                                    }}
                              </span>
                        </div>
                        <span class="f-10 d-flex align-items-center value red">
                            -0.03%
                            <i class="ms-2 fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                        <!-- <span class="f-10 d-flex align-items-center value" :class="[{red: parseFloat(value.lastChangePercentage) < 0, green: parseFloat(value.lastChangePercentage) > 0,},]">
                            {{parseFloat(value.lastChangePercentage).toFixed(2)
                                                                        || 0}}%
                            <i class="ml-2 fa" aria-hidden="true" :class="[{'fa-caret-down': parseFloat(value.lastChangePercentage) < 0, 'fa-caret-up': parseFloat(value.lastChangePercentage) > 0,},]"></i>
                        </span> -->
                    </div>
                </div>
            </Vue3Marquee>
        </section>
        <section class="blockElement space bg-white howWork">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-10 text-center">
                        <h2 class="mb-3">{{$t('FundedAccount.fundedText8')}}</h2>
                        <p>{{$t('FundedAccount.fundedText9')}}</p>
                    </div>
                </div>
                <div class="row customspace">
                    <div class="col-12 col-lg-4">
                        <div class="stageWork text-center columnDir">
                            <div class="counter">
                                <span>1</span>
                            </div>
                            <h3 class="bold">{{$t('FundedAccount.fundedText10')}}</h3>
                            <p>{{$t('FundedAccount.fundedText11')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import { Vue3Marquee } from 'vue3-marquee'
// import { Const } from "@/plugins/vue-provider-cache/const.mod";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {

        };
    },
     components: {
        Vue3Marquee,
    },
}
</script>